/* You can add global styles to this file, and also import other style files */
@import "./assets/fonts/style-font.scss";

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "poppins-regular" !important;
}

b {
  font-family: "poppins-bold" !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@media only screen and (max-width: 1280px) {
  body {
    zoom: 0.8;
    -ms-zoom: 0.8;
    -webkit-zoom: 0.8;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.skeleton-box {
  display: inline-block;
  height: 100%;
  border-radius: 7px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #909090;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.4) 20%,
        rgba(#fff, 0.8) 60%,
        rgba(#fff, 0));
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.text-white {
  color: white !important;
}

.text-gray {
  color: #707070 !important;
}

.text-black {
  color: black !important;
}

.text-blue {
  color: #0058FF !important;
}

.text-red {
  color: #FF0020 !important;
}


.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-20 {
  width: 20%;
}

.h-100 {
  height: 100%;
}

.h-90 {
  height: 90%;
}

.h-80 {
  height: 80%;
}

.h-60 {
  height: 60%;
}

.h-40 {
  height: 40%;
}

.h-20 {
  height: 20%;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
  ;
}

.fs-24 {
  font-size: 24px !important;
}

.fit-size {
  height: inherit;
  width: inherit;
  object-fit: cover;
  object-position: center;
}

.original-size {
  max-width: 100% !important;
  max-height: 100% !important;
}

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.p-menu {
  z-index: 1001;
  transform-origin: center top;
  width: 17rem !important;
}

.p-menu.p-menu-overlay {
  background: #d5d7e3;
  width: fit-content !important;
}

.p-autocomplete {
  display: inline-flex;
  position: relative;
  width: 100%;

  .p-inputtext {
    margin: 0;
    width: 100%;
    border: none !important;
    padding-left: 3rem;
  }
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  border: 0 none;
  padding: 0px !important;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #ffffff !important;
  background: #009dff !important;
  font-weight: bold !important;
}

.p-autocomplete .p-autocomplete-panel {
  min-width: 100%;
  max-height: 50vh !important;
  top: 0;
  left: 0;
}

app-celebrities,
app-detail-club,
app-detail-inliver,
app-list-reports,
app-list-hashtags,
app-detail-hashtag,
app-detail-celeb {
  .p-tabmenu .p-tabmenu-nav {
    background-color: transparent !important;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background-color: transparent !important;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    background-color: transparent !important;
  }
}

p-table {
  .p-datatable .p-datatable-thead>tr>th {
    background: #e6e9f4 !important;
  }
}

app-list-club-scenes {
  .p-dialog-header {
    justify-content: center !important;
  }
}

app-card-post {
  .p-image-preview-container>img {
    height: 100px;
  }
}

app-club-posts-publication {

  // app-list-inliver {
  .p-sidebar-right {
    width: 560px;
  }
}

app-list-hashtags,
app-detail-hashtag {
  .p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    padding: 1.5rem 0.5rem !important;
  }
}

app-card-report,
app-account-info {
  .p-dialog-header {
    justify-content: center !important;
  }

  .p-dropdown {
    width: 100%;
  }
}

app-club-info {
  .p-dropdown {
    width: 100%;
  }
}

app-list-reports,
app-list-club-report-content {
  .p-sidebar-right {
    width: 450px;
  }
}

app-loading {
  z-index: 9999999999;

  .p-dialog {
    box-shadow: none !important;
    background: transparent !important;
  }

  .p-dialog-header,
  .p-dialog-content {
    background: transparent !important;
  }

  .p-dialog .p-dialog-content {
    background: transparent !important;
  }

  .p-dialog .p-dialog-header {
    background: transparent !important;
  }
}

app-list-celebs {
  .p-listbox-list-wrapper {
    height: 269px;
  }
}

.p-dialog .p-dialog-content {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.text-error {
  color: red;
}

.text-center {
  text-align: center;
}


app-list-demandes {
  .p-autocomplete .p-inputtext {
    margin: 0;
    width: 100%;
    border: solid 1px !important;
    padding-left: 1rem !important;
  }
}

.text-underline {
  text-decoration: underline;
}

app-advanced-options {
  .p-tabmenu-nav {
    justify-content: space-between;
  }
}

app-create-post {
  mention-list {
    .mention-dropdown {
      top: 100% !important;
      bottom: auto !important;
      margin-top: 19px !important;
    }
  }
}

app-card-post-inliveteam {
  a {
    color: #50c9ff;
    text-decoration: none;
  }
}
